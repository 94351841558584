$allInOneProtection-color: $white !default;
$allInOneProtection-text-color: $text-blue !default;
$allInOneProtection-details-text-color: $gray-text-default !default;
$allInOneProtection-details-background: $white !default;
$allInOneProtection-background: $primary-blue !default;
$allInOneProtection-button-background: $secondary-green !default;
$allInOneProtection-button-hover-background: $secondary-green-hover !default;

.allInOneProtectionContent {
	width: 98%;
	margin: 0 auto;
	color: $allInOneProtection-color;
	background-color: $allInOneProtection-background;

	.inner {
		padding: 30px;

		h2 {
			font-size: 1.5em;
			font-weight: 600;

			@include media-breakpoint-down(lg) {
				font-size: 1.4em;
			}
		}

		.button-row {
			display: inline-flex;
			align-items: center;
			justify-content: space-between;

			@include media-breakpoint-down(xsm) {
				width: 100%;
			}

			.description {
				width: 75%;
				font-size: 1em;
				line-height: 1.4em;

				&.withLink {
					display: none;
				}

				@include media-breakpoint-down(lg) {
					font-size: 0.9em;
				}

				@include media-breakpoint-down(md) {
					display: none;
				}

				@include media-breakpoint-up(md) {
					display: block;
				}
			}

			.button {
				width: 200px;
				height: 44px;
				margin-left: 15px;
				font-size: 1em;
				line-height: 1.4em;
				cursor: pointer;
				background-color: $allInOneProtection-button-background;
				border: none;
				border-radius: 5px;
				box-shadow: none;

				&:hover {
					background-color: $allInOneProtection-button-hover-background;
				}

				@include media-breakpoint-down(md) {
					margin-top: 10px;
					margin-left: 0;
				}

				@include media-breakpoint-down(xsm) {
					width: 100%;
					height: 100%;
					min-height: 44px;
				}

				i {
					margin-left: 5px;
					font-size: 1.2em;

					vertical-align: middle;

					&::before {
						display: inline-block;
						transition: transform .3s ease;
					}
				}

				.showLess {
					display: inline-block;
				}

				.showMore {
					display: none;
				}

				i:before {
					transform: rotate(180deg);
				}

				&.collapsed {
					.showLess {
						display: none;
					}

					.showMore {
						display: inline-block;
					}

					i:before {
						transform: rotate(0deg);
					}
				}
			}
		}
	}

	.card {
		background-color: $allInOneProtection-details-background;
		border: 1px solid $allInOneProtection-background;
		border-radius: 0;

		.card-body {
			padding: 0;

			@include media-breakpoint-down(md) {
				padding: 0 0 25px 0;
			}
		}

		.text {
			display: inline-flex;
			color: $allInOneProtection-details-text-color;

			@include media-breakpoint-down(md) {
				flex-direction: row;
				flex-wrap: wrap;
			}

			@include media-breakpoint-down(md) {
				.description {
					padding: 25px;
					font-size: 0.9em;
				}

				.separator {
					width: 93%;
					height: 1px;
					margin: 0 25px;
					background-color: $allInOneProtection-background;
				}
			}

			.section {
				padding: 25px;
				font-size: 1em;
				line-height: 1.4em;

				@include media-breakpoint-down(xl) {
					min-height: 130px;

					&:nth-child(even) {
						padding: 15px 25px;
					}
				}

				@include media-breakpoint-down(lg) {
					min-height: 140px;
					font-size: 0.9em;

					&:nth-child(even) {
						padding: 15px 25px;
					}
				}

				@include media-breakpoint-down(md) {
					height: auto;
					min-height: 75px;
					padding: 25px 25px 0 25px;

					&:nth-child(even) {
						padding: 25px;
					}
				}

				.subtitle {
					@include sf_pro_textmedium;
				}
			}
		}

		.allInOne-left, .allInOne-right {
			display: flex;
			flex-direction: column;
		}
	}
}
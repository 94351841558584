@mixin homePageDiscountedPrice($type: top5) {
	&.discount {
		@if $type == top5 {
			bottom: 8px;
			background: $topMatches-item-details-price-discount-bg;
		}
	
		@else {
			background: $topOffers-item-details-price-discount-bg;
		}
		
		width: auto;
		height: 58px;
		padding: 6px;
		text-align: right;
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
	
		&::before {
	
			position: absolute;
			top: 0;
			left: -19px;
			width: 19px;
			height: 58px;
			padding-left: 1px;
			overflow: hidden;
			font-family: "eventTripsIcon" !important;
			font-size: 58px;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			line-height: 1;
			text-transform: none;
			content: "\e955";
			speak: none;
			-webkit-font-smoothing: antialiased;
	
			@if $type == top5 {
				color: $topMatches-item-details-price-discount-bg;
			}
	
			@else {
				color: $topOffers-item-details-price-discount-bg;
			}
		}
	
		.priceLabel, .price-withoutDiscount {
			@if $type == top5 {
				color: $topMatches-item-details-price-withoutDiscount-color;
			}
	
			@else {
				color: $topOffers-item-details-price-withoutDiscount-color;
			}
	
			line-height: 11px;
			text-transform: uppercase;
		}
	
		.priceLabel {
			font-size: 11px;

			@if $type == top5 {
				color: $white;
			}
		}
	
		.price-withDiscount {
			@if $type == top5 {
				color: $topMatches-item-details-price-withDiscount-color;
			}
	
			@else {
				color: $topOffers-item-details-price-withDiscount-color;
			}
	
			font-size: 20px;
			line-height: 22px;
	
			@include sf_pro_textbold;
	
			@include media-breakpoint-down(lg) {
				font-size: 16.6px;
			}
		}
	
		.price-withoutDiscount {
			font-size: 14px;
			line-height: 12px;
			text-decoration: line-through;
		}

		@if $type == top_offers {
			.price {
				font-size: 28px;
				color: $topOffers-item-details-price-withDiscount-color;

				@include sf_pro_textbold;	
			}
		}
	}

	
	&.discountWithPaymentInstallmentsInfo {

		bottom: 15px;
		text-align: right;

		@include media-breakpoint-down(lg) {
			bottom: 5px;
		}

		&::before {
			@if $type == top5 {
				color: $topMatches-item-details-price-discount-bg;
			}

			@else {
				color: $topOffers-item-details-price-discount-bg;
			}
		}

		.priceLabel, .price-withoutDiscount {
			@if $type == top5 {
				color: $topMatches-item-details-price-withoutDiscount-color-2;
			}

			@else {
				color: $topOffers-item-details-price-withoutDiscount-color;
			}

			line-height: 11px;
			text-transform: uppercase;
		}

		.priceLabel {
			font-size: 11px;
		}

		.price-withDiscount {
			@if $type == top5 {
				color: $topMatches-item-details-price-withDiscount-color-2;
			}

			@else {
				color: $topOffers-item-details-price-withDiscount-color;
			}
			display: inline-block;
			font-size: 20px;
			line-height: 22px;

			@include sf_pro_textbold;

			@include media-breakpoint-down(lg) {
				font-size: 16.6px;
			}

			@include media-breakpoint-down(sm) {
				font-size: 20px;
			}
		}

		.price-withoutDiscount {
			display: block;
			float: left;
			margin-right: 5px;
			font-size: 14px;
			line-height: 25px;
			text-decoration: line-through;
		}
	}
}

@mixin matchPageDiscountedPrice() {
	position: relative;
	display: inline-block;
	padding-top: 4px;
	padding-right: 5px;
	padding-bottom: 4px;
	background: $white;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;

	.background {
		position: absolute;
		top: 0;
		left: -17px;
		z-index: 1;
		width: 17px;
		overflow: hidden;
		font-size: 57px;
		color: $white;
	}

	.price-before, .price-withOutDiscount, .price-withDiscount {
		position: relative;
		z-index: 2;
	}

	.price-before, .price-withOutDiscount {
		font-size: 10px;
		line-height: 15px;
		color: rgba(56, 67, 142, 0.88);

		@include sf_pro_textlight;
	}

	.price-before {
		text-transform: uppercase;
	}

	.price-withDiscount {
		font-size: 19px;
		line-height: 19px;
		color: $primary-blue;

		@include sf_pro_textsemibold;
	}

	.price-withOutDiscount {
		font-size: 12px;
		text-decoration: line-through;
	}
}

@mixin homePageArrangementOfTheWeekDiscountedPrice() {
	&.discount {
		right: 10px;
		width: 100px;
		height: 100px;
		margin: 0;
		font-family: "eventTripsIcon" !important;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		line-height: 1;
		text-transform: none;
		speak: none;
		-webkit-font-smoothing: antialiased;

		@include media-breakpoint-between(sm, md) {
			right: unset;
			left: 0;
			width: 80px;
			height: 80px;

			&::before {
				font-size: 94px;
			}

			.price-container {
				top: 16px;

				.priceLabel {
					font-size: 9px;
				}

				.price-withDiscount {
					font-size: 24px;
				}

				.price-withoutDiscount {
					font-size: 14px;
				}
			}
		}

		@include media-breakpoint-down(sm) {
			margin: 10px auto;
		}

		&::before {
			position: absolute;
			top: -8px;
			left: -8px;
			font-size: 114px;
			color: $yellow-1;
			content: "\e92e";
		}

		.price-container {
			position: absolute;
			top: 21px;
			width: 100%;

			.priceLabel, .price-withDiscount, .price-withoutDiscount {
				color: $yellow-1;
				text-align: center;
			}

			.priceLabel {
				font-size: 10px;
				text-transform: uppercase;
			}

			.price-withDiscount {
				font-size: 28px;
				color: $black;

				@include sf_pro_textbold;
			}

			.price-withoutDiscount {
				font-size: 16px;
				text-decoration: line-through;
			}
		}
	}
}